import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { LocationGetDto } from 'src/dto/GetDtos/location-get-dto';
import { LocationStoreService } from 'src/stores/location-store.service';

@Injectable({
  providedIn: 'root',
})
export class SelectedLocationService implements OnDestroy {
  private onDestroy$ = new Subject();
  private _selectedLocation$ = new ReplaySubject<LocationGetDto | undefined>(1);
  selectedLocation?: LocationGetDto;
  selectedLocation$;
  constructor(
    private locationStore: LocationStoreService,
    private route: ActivatedRoute,
  ) {
    this.selectedLocation$ = combineLatest([
      this.locationStore.items$,
      this._selectedLocation$,
    ]).pipe(
      map(([locations, selectedLocation]) =>
        selectedLocation
          ? locations.find((location) => location.id == selectedLocation.id)
          : selectedLocation,
      ),
      map((location) =>
        JSON.stringify(location) == JSON.stringify(this.selectedLocation)
          ? this.selectedLocation
          : location,
      ),
      distinctUntilChanged(),
      tap((selectedLocation) => (this.selectedLocation = selectedLocation)),
      shareReplay(1),
    );

    this.route.queryParams
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((params) => {
          let id = params['locationId'];
          if (id != null) {
            return this.locationStore.getSingle(id);
          }
          return of(undefined);
        }),
      )
      .subscribe((item: LocationGetDto | undefined) => {
        this.setSelectedLocation(item);
      });
  }
  setSelectedLocation(location: LocationGetDto | undefined) {
    this._selectedLocation$.next(location);
  }
  reload() {
    if (this.selectedLocation) {
      const id = this.selectedLocation.id;
      this.selectedLocation = undefined;
      this.locationStore.refreshItem(id);
    }
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
